import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ContrastType, HomeCard, File, CardImageStyle, Card } from "db"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX24 } from "theme/sizes"
import CardLabel from "ui/CardLabel"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Title from "ui/Title"
import ImageBase from "app/files/components/Image"
import { getCardLinkProps } from "app/leerkracht/utils/getCardLinkProps"
import { Value } from "@stringtale/react"
import image from "test/__mocks__/image"

const Root = styled.div<{
  backgroundColor: string | null
  contrast: ContrastType
  imageStyle: CardImageStyle
  hasImage: boolean
}>`
  position: relative;
  border-radius: 8px;

  /* width: 436px; */
  height: 255px;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px #00000033;
  text-decoration: none;

  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 8px;

  ${({ imageStyle }) =>
    imageStyle === "CONTAIN" &&
    css`
      padding: 8px;
    `};

  ${({ contrast }) =>
    contrast === "DARK"
      ? css`
          color: white;
        `
      : css`
          color: ${DARK_BLUE_COLOR};
        `};
  ${({ backgroundColor, contrast, hasImage, imageStyle }) =>
    hasImage && imageStyle === "FILL"
      ? css`
          background-color: transparent;
        `
      : backgroundColor
        ? css`
            background-color: ${backgroundColor};
          `
        : contrast === "DARK"
          ? css`
              background-color: ${DARK_BLUE_COLOR};
            `
          : css`
              background-color: white;
            `}
`

const Left = styled("div")<{ imageStyle: CardImageStyle }>`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-rows: 1fr auto;
  z-index: 2;

  ${({ imageStyle }) =>
    imageStyle === "CONTAIN"
      ? css`
          padding: 24px 0 24px 24px;
        `
      : css`
          padding: 32px 0 32px 32px;
        `};
`

const Button = styled(RoundedButton)`
  height: 32px;
  width: 116px;
  padding: 8px 16px;
`

const ImageWrapper = styled.div<{ imageStyle: CardImageStyle }>`
  width: 100%;
  height: 100%;
  /* position: relative; */

  ${({ imageStyle }) =>
    imageStyle !== "FILL"
      ? css`
          position: relative;
        `
      : css`
          z-index: 1;
        `}

  img {
    border-radius: 8px;
    ${({ imageStyle }) =>
      imageStyle === "CONTAIN"
        ? css`
            object-fit: contain;
          `
        : css`
            object-fit: cover;
          `}
  }
`

const Image = styled(ImageBase)`
  object-fit: cover;
`

const Label = styled(CardLabel)`
  line-height: 1.8;
`

const ActueelCard = ({
  content,
}: {
  content: HomeCard & { image: File | null }
}) => {
  return (
    <Root
      contrast={content.contrast}
      backgroundColor={content.backgroundColor}
      imageStyle={content.imageStyle}
      hasImage={!!content.image}
      {...getCardLinkProps({ ...content, type: "EXTERN" })}
    >
      <Label color={DARK_BLUE_COLOR}>{content.label}</Label>
      <Left imageStyle={content.imageStyle}>
        <div></div>
        <Stack gap="16px" justify="space-between">
          <Title size={PX24}>{content.title}</Title>
          <div>
            <Button size="small" variant="filled">
              {content.buttonText && content.buttonText.length > 1 ? (
                content.buttonText
              ) : (
                <Value name="">Lees meer</Value>
              )}
            </Button>
          </div>
        </Stack>
      </Left>
      <Stack>
        <ImageWrapper imageStyle={content.imageStyle}>
          {content.image && (
            <Image
              file={content.image}
              fill
              sizes="(max-width: 767px) 100vw, (max-width: 1024px) 50vw, 33vw"
              alt=""
              ratio={content.imageStyle === "FILL" ? 9 / 16 : undefined}
              options={
                content.imageStyle === "CONTAIN"
                  ? {
                      fit: "contain",
                    }
                  : {
                      fit: "cover",
                    }
              }
            />
          )}
        </ImageWrapper>
      </Stack>
    </Root>
  )
}

export default ActueelCard
