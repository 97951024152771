import { getSession } from "@blitzjs/auth"
import { BlitzPage, Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import CategorySlider from "app/leerkracht/components/CategorySlider"
import BulletinBoardCard, {
  getLinkProps as getBulletinLinkProps,
} from "app/bulletinboard/components/BulletinBoardCard"
import BulletinBoardGridBase from "app/bulletinboard/components/BulletinBoardGrid"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import { addBulletinItemModalOpenAtom } from "app/bulletinboard/state"
import Breadcrumb from "app/core/components/Breadcrumb"
import { ORDER_BY } from "app/core/consts"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { getIsExamine } from "app/core/hooks/useIsExamine"
import { getIsReopened } from "app/core/hooks/useIsReopened"
import { getIsSubmitted } from "app/core/hooks/useIsSubmitted"
import { getLink } from "app/core/hooks/useLinks"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import DashboardLayout from "app/core/layouts/DashboardLayout"
import ActueelCard from "app/home/components/ActueelCard"
import HomeModal from "app/home/components/HomeModal"
import { parseAsBoolean, parseAsString, useQueryState } from "nuqs"

import {
  MODAL_QUERY_ARCHIVE_KEY,
  MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE,
} from "app/playlists/consts"

import getHomePageInfo from "app/home/queries/getHomePageInfo"
import BlueprintModal from "app/schooljaren/components/BlueprintModal"
import SchoolyearBanner from "app/schooljaren/components/SchoolyearBanner"
import { useHasSchoolyears } from "app/schooljaren/hooks/useHasSchoolyears"
import getBlueprint, {
  GetBlueprintResult,
} from "app/schooljaren/queries/getBlueprint"
import getCurrentUser from "app/users/queries/getCurrentUser"

import db from "db"
import { useAtom } from "jotai"
import { GetServerSidePropsContext } from "next"
import Link from "next/link"

import { useState } from "react"

import { css } from "@emotion/react"
import { TbEdit } from "@react-icons/all-files/tb/TbEdit"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { HomepageMessageBanner } from "app/home/components/HomepageMessageBanner"
import shouldShowNewSchoolyearInfo from "app/schooljaren/queries/shouldShowNewSchoolyearInfo"
import { isManager } from "app/users/utils/isManager"
import { useRouter } from "next/router"
import { LIGHT_GREEN_COLOR, PX14, PX20, PX24, PX32 } from "theme/consts"
import Container from "ui/Container"
import Group from "ui/Group"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import TextButton from "ui/TextButton"
import { default as Title } from "ui/Title"
import getMigrationDialog from "app/schooljaren/queries/getMigrationDialog"
import MigrationHomeDialog from "app/schooljaren/components/MigrationHomeDialog"
import getMigrationBanner from "app/schooljaren/queries/getMigrationBanner"
import closeGroupMessage from "app/schooljaren/mutations/closeGroupMessage"
import { useCurrentYear } from "app/schooljaren/hooks/useCurrentYear"

type Color = "green" | "yellow"

const Root = styled.div`
  container-type: inline-size;
`

const Header = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0px;
  margin-bottom: 40px;
`

const EmptyBulletinBoardItem = styled.div`
  aspect-ratio: 1/1;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
`

const AddButton = styled(RoundedButton)`
  aspect-ratio: 1/1;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  box-shadow: 0px 2px 10px #00000033;
`

const BulletinBoardGrid = styled(BulletinBoardGridBase)<{ itemCount: number }>`
  display: grid;
  grid-template-rows: 1fr;
  @container (min-width: 1280px) {
    ${({ itemCount }) => calculateEmptySlotAmount(itemCount, 5)};
  }
  @container (min-width: 1024px) AND (max-width: 1279px) {
    grid-template-columns: repeat(4, 1fr);
    ${({ itemCount }) => calculateEmptySlotAmount(itemCount, 4)}
  }
  @container (min-width: 768px) AND (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    ${({ itemCount }) => calculateEmptySlotAmount(itemCount, 3)}
  }
  @container (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    ${({ itemCount }) => calculateEmptySlotAmount(itemCount, 2)}
  }
`

const GridWrapper = styled.div`
  position: relative;
  width: 100%;
`

const calculateEmptySlotAmount = (itemCount: number, overflow: number) => {
  if (itemCount > overflow) {
    return css`
      & > :nth-child(n + ${overflow * 2 + 1}) {
        display: none;
      }
    `
  } else {
    return css`
      & > :nth-child(n + ${overflow + 1}) {
        display: none;
      }
    `
  }
}

const EmptyItem = styled.div`
  background-color: #2871631a;
  border-radius: 8px;
  aspect-ratio: 1/1;
  position: relative;
  overflow: clip;
`

const ActueelCards = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  gap: 24px;

  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-items: stretch;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

const shouldShowManagerBlueprintInfo = function ({
  hasSchoolyears,
  blueprint,
  isManager,
}: {
  isManager: boolean
  hasSchoolyears: boolean
  blueprint: GetBlueprintResult
}) {
  if (!hasSchoolyears) return false

  // Return true if you have schoolyears, it is a schoolyear period and you've either no blueprint or a blueprint concept
  return (
    hasSchoolyears &&
    isManager &&
    blueprint &&
    (!blueprint.blueprints[0] || blueprint.blueprints[0].state === "CONCEPT")
  )
}

const BannerContainer = styled(Stack)`
  & > *:last-child {
    margin-bottom: 40px;
  }
`

const Home: BlitzPage = () => {
  const [currentUser] = useQuery(getCurrentUser, {})
  const currentGroup = useCurrentGroup()
  const currentSchool = useCurrentOrganization()
  const currentSchoolyear = useCurrentYear()
  const [homePage] = useQuery(getHomePageInfo, undefined, {
    staleTime: currentUser?.role === "ADMIN" ? 0 : 600000,
  })

  const [bulletinBoardItems] = useQuery(getBulletinBoardItems, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 30000,
  })
  const [migrationDialog] = useQuery(getMigrationDialog, undefined)
  const [migrationBanner] = useQuery(getMigrationBanner, undefined)
  const [closeGroupMessageMutation] = useMutation(closeGroupMessage)
  const [_isArchiveOpen, setOpenArchive] = useQueryState(
    MODAL_QUERY_ARCHIVE_KEY
  )

  const { state: stateNewSchoolyearBanner, toggle: toggleNewSchoolyearBanner } =
    usePopoverSeen("NEW_SCHOOLYEAR")

  const router = useRouter()

  const [isAddLessonOpen, setIsAddLessonOpen] = useAtom(
    addBulletinItemModalOpenAtom
  )

  const [_open, setOpen] = useQueryState("bibliotheek", parseAsString)

  const [blueprint] = useQuery(getBlueprint, {
    refetchOnWindowFocus: false,
  })

  const hasSchoolyears = useHasSchoolyears()

  const userCreatedInCurrentYear =
    currentUser &&
    currentUser.role !== "ADMIN" &&
    currentSchoolyear &&
    currentUser.createdAt >= currentSchoolyear.yearStartDate &&
    currentUser.createdAt <= currentSchoolyear.yearEndDate

  const [hasJustClosedBlueprintModal, setHasJustClosedBlueprintModal] =
    useState(false)

  const showManagerBlueprintInfo = shouldShowManagerBlueprintInfo({
    hasSchoolyears,
    blueprint,
    isManager: isManager({ currentUser, organizationId: currentSchool?.id }),
  })

  const [newSchoolyearStarted] = useQuery(shouldShowNewSchoolyearInfo, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const showNewSchoolyearManagerInfo =
    newSchoolyearStarted &&
    stateNewSchoolyearBanner === "OPENED" &&
    isManager({ currentUser, organizationId: currentSchool?.id })

  return (
    <>
      {migrationDialog !== "NONE" && !userCreatedInCurrentYear && (
        <MigrationHomeDialog variant={migrationDialog} />
      )}
      <Root>
        <Stack gap="0px">
          {currentUser?.role !== "ADMIN" && (
            <Breadcrumb
              backgroundColor={LIGHT_GREEN_COLOR}
              items={[
                <Breadcrumb.Item isActive>
                  {currentSchool?.displayTitle}
                </Breadcrumb.Item>,
              ]}
            />
          )}
          <Header>
            <Stack gap="40px" align="stretch">
              <Stack>
                <Container>
                  <Stack>
                    <BannerContainer gap="8px">
                      {migrationBanner &&
                      newSchoolyearStarted &&
                      !userCreatedInCurrentYear ? (
                        <HomepageMessageBanner
                          variant="orange"
                          buttonAction={async () => {
                            await setOpenArchive("")
                          }}
                          buttonText={
                            <Value name="apps.web.pages.index.open_archief">
                              Open archief
                            </Value>
                          }
                          onCloseAction={async () => {
                            await closeGroupMessageMutation({
                              type: "MIGRATE_BANNER",
                            })
                            await invalidateQuery(getMigrationBanner)
                          }}
                        >
                          <Title size={PX20}>
                            <Value name="apps.web.pages.index.wil_je_schoollijsten_van_vorig_jaar_gebruiken">
                              Wil je de lijsten van vorig jaar bekijken?
                            </Value>
                          </Title>
                        </HomepageMessageBanner>
                      ) : null}
                      {showManagerBlueprintInfo && blueprint && (
                        <SchoolyearBanner blueprint={blueprint} />
                      )}
                      {showNewSchoolyearManagerInfo &&
                        !userCreatedInCurrentYear && (
                          <HomepageMessageBanner
                            variant="orange"
                            buttonAction={async () => {
                              await router.push(Routes.SchoolbeheerPage())
                            }}
                            buttonText={
                              <Value name="apps.web.pages.index.new_schoolyear_close_button">
                                Aan de slag!
                              </Value>
                            }
                            onCloseAction={async () => {
                              await toggleNewSchoolyearBanner("CLOSED")
                            }}
                          >
                            <Title size={PX20}>
                              {currentSchool?.schoolyearResult ===
                              "BLUEPRINT_USED" ? (
                                <Value name="apps.web.pages.index.controleer_de_leerkrachtaccounts_voor_het_nieuwe_schooljaar">
                                  Controleer de leerkrachtaccounts voor het
                                  nieuwe schooljaar.
                                </Value>
                              ) : (
                                <Value name="apps.web.pages.index.controleer_de_groepsindeling_en_de_leerkrachtaccounts">
                                  Controleer de groepsindeling en de
                                  leerkrachtaccounts.
                                </Value>
                              )}
                            </Title>
                          </HomepageMessageBanner>
                        )}
                    </BannerContainer>
                    <Stack
                      align="flex-start"
                      gap="16px"
                      style={{ paddingBottom: 60 }}
                    >
                      <Group align="center" gap="20px">
                        <Group align="center" gap="16px">
                          <AddButton
                            variant="filled"
                            onClick={() => setIsAddLessonOpen(true)}
                          >
                            <TbPlus size={24} />
                          </AddButton>
                          <Title weight="900" size={PX32}>
                            <Value
                              name="apps.web.pages.index.prikbord_group"
                              format={{
                                groupName: currentGroup?.displayTitle,
                              }}
                            >
                              {`Prikbord {groupName}`}
                            </Value>
                          </Title>
                        </Group>
                        <TextButton
                          size={PX14}
                          onClick={() =>
                            setOpen(MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE)
                          }
                        >
                          <Value name="apps.web.pages.index.aanpassen">
                            Aanpassen
                          </Value>
                          <TbEdit size={16} strokeWidth={3} />
                        </TextButton>
                      </Group>
                      <GridWrapper>
                        <BulletinBoardGrid
                          itemCount={bulletinBoardItems.length}
                        >
                          {bulletinBoardItems.map((item) => (
                            <BulletinBoardCard
                              key={item.id}
                              item={item}
                              {...getBulletinLinkProps(item)}
                            />
                          ))}
                          {[...Array(10)].map(function (x, i) {
                            return <EmptyItem key={i} />
                          })}
                        </BulletinBoardGrid>
                      </GridWrapper>
                    </Stack>
                  </Stack>
                </Container>
              </Stack>
            </Stack>
          </Header>
          {homePage && homePage.homeCards?.length > 0 && (
            <Container.Root>
              <Stack gap="60px">
                <Container.Content>
                  <Stack align="flex-start" gap="32px">
                    <Title size={PX24} weight={"900"}>
                      <Value name="apps.web.pages.index.uitgelicht_voor_jouw_groep">
                        Uitgelicht voor jouw groep
                      </Value>
                    </Title>

                    <ActueelCards>
                      {homePage.homeCards.map((content, index) => (
                        <ActueelCard content={content} />
                      ))}
                    </ActueelCards>
                  </Stack>
                </Container.Content>
                <Container.Content>
                  <Stack align="flex-start" gap="32px">
                    <Group gap="16px" align="center">
                      <Title size={PX24} weight={"900"}>
                        <Value name="apps.web.pages.index.categorieen">
                          Categorieën
                        </Value>
                      </Title>
                      <TextButton
                        size={PX14}
                        as={Link}
                        {...Routes.CategoriesPage()}
                      >
                        <Value name="apps.web.pages.index.bekijk_alles_categorieen">
                          Bekijk alles
                        </Value>
                        &nbsp;&gt;
                      </TextButton>
                    </Group>
                    <CategorySlider />
                  </Stack>
                </Container.Content>
              </Stack>
            </Container.Root>
          )}
        </Stack>
      </Root>
      {blueprint ? (
        <BlueprintModal
          blueprint={blueprint}
          setHasJustClosedBlueprintModal={setHasJustClosedBlueprintModal}
        />
      ) : (
        // Disable Nieuwsflits #18 modal until there's a new one
        !hasJustClosedBlueprintModal && <HomeModal />
        // !hasJustClosedBlueprintModal && null
      )}
    </>
  )
}

Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => <DashboardLayout type="HOME">{page}</DashboardLayout>

export default Home

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const reopen = getIsReopened(ctx.query)
  const examine = getIsExamine(ctx.query)
  const submitted = getIsSubmitted(ctx.query)

  const saveId = reopen || examine || submitted

  if (!saveId)
    return {
      props: {},
    }

  /// Redirect root /ingeleverd/:saveId, /nakijken/:saveId etc, to first submittable slide

  const session = await getSession(ctx.req, ctx.res)
  if (!session) return { notFound: true }

  const savedSubject = await db.savedSubject.findFirst({
    where: {
      id: saveId,
    },
    include: {
      subject: {
        include: {
          headSubject: {
            include: {
              group: {
                include: {
                  lesson: true,
                },
              },
            },
          },
          slides: {
            where: {
              isSubmittable: !!submitted || !!examine ? true : undefined,
            },
            take: 1,
            ...ORDER_BY,
          },
        },
      },
    },
  })

  if (!savedSubject) {
    return { notFound: true }
  }

  const { href } = getLink(
    ctx.query,
    Routes.SlidePageIndex({
      lesson: savedSubject.subject.headSubject.group.lesson.slug,
      head: savedSubject.subject.headSubject.slug,
      sub: savedSubject.subject.slug,
      slide: savedSubject.subject.slides[0].slug,
    })
  )
  return {
    redirect: {
      permanent: false,
      destination: href,
    },
  }
}
