import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { LandscapeYoutubePlayer } from "app/content-blocks/components/YoutubePlayer"
import MinimizedButton from "app/core/components/MinimizedButton"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import { PageType } from "db"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { PX24 } from "theme/sizes"
import Group from "ui/Group"
import { Close, Content, Overlay, Root } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Title from "ui/Title"

const HOME_MODAL_KEY: PageType = "HOME_KERST_WENS_2024"
const today = new Date()
const currentYear = today.getFullYear()

export default function HomeModal() {
  const { state, toggle } = usePopoverSeen(HOME_MODAL_KEY)

  const modalActive = state === "OPENED"
  if (state === "CLOSED") {
    return null
  }

  if (HOME_MODAL_KEY === PageType.HOME_KERST_WENS_2024 && currentYear > 2024) {
    return null
  }

  return (
    <Dialog.Root
      open={modalActive}
      onOpenChange={(val) => toggle(val ? "OPENED" : "MINIMIZED")}
    >
      {
        <AnimatePresence>
          {!modalActive && (
            <Dialog.Trigger asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <MinimizedButton onClose={() => toggle("CLOSED")}>
                  <Value name="apps.web.src.home.components.homemodal.button">
                    Kerstwens
                  </Value>
                </MinimizedButton>
              </motion.div>
            </Dialog.Trigger>
          )}
        </AnimatePresence>
      }
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Root>
            <Content>
              <Stack gap="32px">
                <Title size={PX24}>
                  <Value name="apps.web.src.home.components.homemodal.title">
                    Kerstwens
                  </Value>
                </Title>
                <Stack gap="24px">
                  <p>
                    <Value name="apps.web.src.home.components.homemodal.fijne_feestdagen">
                      Namens het team van 123ZING wensen we jullie vrolijke
                      feestdagen en gelukkig 2025!
                    </Value>
                  </p>
                  <LandscapeYoutubePlayer videoId={"ilCRwJfvhBA"} />
                  <Stack>
                    <Value
                      name="apps.web.src.home.components.homemodal.body"
                      format={{
                        p: (c) => <p>{c}</p>,
                        li: (c) => <li>{c}</li>,
                        ul: (c) => <ul>{c}</ul>,
                        b: (c) => <b>{c}</b>,
                        kerstLink: (c) => (
                          <Link
                            href={
                              "https://mijn.123zing.nl/les/meespelen-met-de-sneeuwwals"
                            }
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                      }}
                    >
                      {`<p>Ook meespelen met de Sneeuwwals op boomwhackers of andere instrumenten met kleurnotatie? <b><kerstLink>Bekijk de les</kerstLink></b></p>`}
                    </Value>
                  </Stack>
                </Stack>
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton variant="filled">
                      <Value name="apps.web.src.news.components.home.sluiten">
                        Later bekijken
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  {/* <RoundedButton variant="filled">
                    <Value name="apps.web.src.news.components.home.meer_informatie">
                      Meer informatie
                    </Value>
                  </RoundedButton> */}
                </Group>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
